.selectize-control {
  padding: 0;
  border: 0;

  &.multi .selectize-input {
    &.has-items {
      padding-left: 6px;
    }

    > div {
      font-size: $h5-font-size;
      margin: 0 6px 2px 0;
      padding: 2px 8px;
      border-radius: $border-radius;
      background-color: $min-white;
      color: inherit;
    }
  }

  .remove {
    font-size: inherit !important;
    font-weight: normal !important;
    border-left: 0 !important;
    color: $text-muted !important;
  }
}

.selectize-input {
  display: flex !important;
  height: 100%;
  border: 1px solid $border-color;
  padding: $input-padding-y $input-padding-x;
  font-size: $input-font-size;
  box-shadow: none;
  line-height: $input-line-height;
  background-color: inherit !important;
  color: inherit !important;

  &.dropdown-active {
    border-radius: $border-radius;
  }

  .selectize-control.single & {
    background: $form-select-bg escape-svg($form-select-indicator) $form-select-bg;
  }

  &.focus {
    border-color: $form-select-focus-border-color;
    box-shadow: $form-select-focus-box-shadow;
  }

  &:after {
    content: none !important;
  }
}

.selectize-dropdown {
  height: auto;
  background: $form-select-bg;
  padding: $input-padding-y 0;
  margin-top: 1px;

  .option,
  .option-header {
    padding: .25rem $input-padding-x;
  }

  .active {
    background: $hover-bg;
    color: inherit;
    cursor: pointer;
  }
}
