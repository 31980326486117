@import "typo/hr";

.lead {
  line-height: 1.4;
}

a {
  text-decoration-skip-ink: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  a {
    color: inherit;
  }
}

strong,
.strong,
b {
  font-weight: $font-weight-bold;
}

blockquote {
  padding-left: 1rem;
  color: $text-muted;
  border-left: 2px solid $border-color;

  p {
    margin-bottom: 1rem;
  }

  cite {
    display: block;
    text-align: right;

    &:before {
      content: "— ";
    }
  }
}

hr {
  margin: 2rem 0;
}

dl {
  dd {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

code {
  padding: 2px 4px;
  background: rgba($code-color, .03);
  border: 1px solid rgba($code-color, .064);
  border-radius: $border-radius;
}


pre {
  padding: 1rem;
  overflow: auto;
  font-size: $code-font-size;
  hyphens: none;
  line-height: $line-height-base;
  tab-size: 3;
  border-radius: $border-radius;
  white-space: pre-wrap;

  code {
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
  }
}

img {
  max-width: 100%;
}


/**
Selection
 */
::selection {
  color: #fff;
  background-color: lighten($primary, 8%);
}

/**
Links
 */
[class^="link-"], [class*=" link-"] {
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
  }
}

/**
Subheader
 */
.subheader {
  @include subheader;
}

/**
Antialiasing
 */
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}


